/*
 * @Author: Matthew Auld
 * @Date: 2019-02-09 14:35:57
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-02-21 06:13:04
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./SubPageHeader.scss";

class PageHeader extends Component {
	state = {};
	render() {
		return (
			<div
				className={
					"page-header " +
					(this.props.className !== undefined
						? this.props.className
						: "")
				}
			>
				<Link
					className="backToMenu text-center"
					to={
						this.props.hasOwnProperty("backLink")
							? this.props.backLink
							: "/"
					}
					onClick={
						this.props.hasOwnProperty("backClick")
							? this.props.backClick
							: () => {}
					}
					style={{ minWidth: 48 }}
				>
					<FontAwesomeIcon
						icon={
							this.props.hasOwnProperty("icon")
								? this.props.icon
								: ["fas", "question"]
						}
					/>
				</Link>
				<h1>{this.props.hasOwnProperty("text") && this.props.text}</h1>
				{this.props.hasOwnProperty("afterElements") &&
					this.props.afterElements}
			</div>
		);
	}
}

export default PageHeader;
