/*
 * @Author: Matthew Auld
 * @Date: 2019-02-10 22:13:35
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-02-21 12:02:03
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import PageHeader from "../../components/SubPageHeader";
import "./search-page.scss";
import Logo from "../../logo.svg";
import MemorySection from "../../components/MemorySection";
import { MEMORIES } from "../../API";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";

class SearchPage extends Component {
	_isMounted = false;
	_currentPage = 0;
	_scrollTop = 0;
	state = {
		memories: [],
		isLoading: true,
		loadMore: false,
		anyMore: false,
		searchVisible: false,
		hasSearched: false,
		searchTerm: null
	};

	componentDidMount() {
		this._isMounted = true;
		if (localStorage.getItem("backToSearch")) {
			let mems = JSON.parse(localStorage.getItem("backToSearch"));
			this._scrollTop = window.searchScrollTop;
			$("#txt-search").val(localStorage.getItem("search-term"));
			this.setState({
				memories: mems,
				searchTerm: localStorage.getItem("search-term"),
				isLoading: false,
				hasSearched: true
			});
		} else if (localStorage.getItem("search-term")) {
			$("#txt-search").val(localStorage.getItem("search-term"));
			this.setState({
				searchVisible: true
			});
			this.performSearch();
		} else if (window.hasOwnProperty("searchObjects")) {
			this._currentPage = window.searchCurrentPage;
			this._scrollTop = window.searchScrollTop;
			this.setState(
				{
					memories: window.searchObjects,
					isLoading: false
				},
				() => {
					$("#memory-section").animate(
						{
							scrollTop: this._scrollTop
						},
						100
					);
				}
			);
		} else {
			setTimeout(() => {
				this.loadNextPage(() => {});
			}, 500);
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	loadNextPage(cb) {
		MEMORIES.loadPage(this._currentPage, json => {
			if (json.success) {
				let memories = this.state.memories;
				json.memories.forEach((m, key) => {
					m.delay = key;
					memories.push(m);
				});

				if (json.memories.length !== 0) {
					this._currentPage = this._currentPage + 1;
				}

				if (json.memories.length === 0) {
					if (this._isMounted)
						this.setState({
							anyMore: true
						});
				}

				window.searchObjects = memories;
				window.searchCurrentPage = this._currentPage;
				window.searchScrollTop = this._scrollTop;

				if (this._isMounted) {
					this.setState({
						memories,
						isLoading: false
					});
				}
			}
			if (cb !== undefined && cb !== null && typeof cb === "function") {
				cb();
			}
		});
	}

	showInput(e) {
		e.preventDefault();
		this.setState({
			searchVisible: !this.state.searchVisible
		});
	}

	handleKeyboard(e) {
		if (e.keyCode === 13) {
			this.performSearch();
		} else if (e.keyCode === 8 && this.state.searchTerm !== null) {
			// Backspace
			localStorage.removeItem("search-term");
			localStorage.removeItem("backToSearch");
			this._currentPage = 0;

			if (this._isMounted) {
				this.setState({
					memories: [],
					isLoading: true,
					hasSearched: false,
					searchTerm: null
				});
				this.loadNextPage();
			}
		}
	}

	performSearch() {
		let query = $("#txt-search").val();

		if (this._isMounted) {
			this._currentPage = 0;
			this.setState({
				isLoading: true,
				hasSearched: true,
				searchTerm: query
			});

			MEMORIES.searchMEmories(query, json => {
				const { memories } = json;
				let mems = [];
				memories.forEach((m, key) => {
					m.delay = key;
					mems.push(m);
				});

				localStorage.setItem("search-term", query);
				if (this._isMounted)
					this.setState(
						{
							memories: mems,
							isLoading: false,
							searchTerm: query
						},
						() => {
							setTimeout(() => {
								this.forceUpdate();
								localStorage.setItem(
									"backToSearch",
									JSON.stringify(mems)
								);
								if (this._isMounted)
									this.setState({
										searchVisible: false
									});
							}, 250);
						}
					);
			});
		}
	}

	render() {
		let scrollMemories = e => {
			let height = $("#memory-section").height();
			let scrollTop = $("#memory-section").scrollTop();
			let scrollHeight = $("#memory-section")[0].scrollHeight;
			this._scrollTop = scrollTop;
			if (
				height + scrollTop >= scrollHeight / 2 &&
				!this.state.loadMore &&
				!this.state.anyMore
			) {
				if (!this.state.hasSearched) {
					if (this._isMounted)
						this.setState({
							loadMore: true
						});
					this.loadNextPage(() => {
						if (this._isMounted)
							this.setState({
								loadMore: false
							});
					});
				}
			}
		};

		return (
			<div id="search-page">
				<PageHeader
					text="Search ME.mory"
					icon={["fas", "search"]}
					backClick={() => {
						localStorage.removeItem("search-term");
						localStorage.removeItem("backToSearch");
					}}
					backLink={
						window.hasOwnProperty("previousLocation") &&
						!window.previousLocation.startsWith("/view/")
							? window.previousLocation
							: "/"
					}
					afterElements={
						<div style={{ width: 32, marginRight: ".5rem" }} />
					}
				/>
				<div id="search-dropdown">
					<div
						id="search-drawer"
						className={this.state.searchVisible ? "open" : ""}
					>
						<div className="input-group">
							<input
								type="text"
								placeholder="Search for a ME.mory"
								className="form-control"
								style={{ borderColor: "#FFF" }}
								id="txt-search"
								autoComplete="off"
								onKeyUp={this.handleKeyboard.bind(this)}
							/>
							<div className="input-group-append">
								<button
									className="btn btn-outline-light"
									type="button"
									onClick={this.performSearch.bind(this)}
								>
									<FontAwesomeIcon icon={["fas", "search"]} />
								</button>
							</div>
						</div>
					</div>
					<button
						className="btn btn-me btn-sm w-100 rounded-0"
						onClick={this.showInput.bind(this)}
					>
						{!this.state.searchVisible ? (
							<FontAwesomeIcon icon={["fas", "chevron-down"]} />
						) : (
							<FontAwesomeIcon icon={["fas", "chevron-up"]} />
						)}
					</button>
				</div>
				<div
					className="page-rest scrollable"
					id="scrollSearch"
					onScroll={scrollMemories.bind(this)}
				>
					{this.state.isLoading ? (
						this.state.searchTerm === null ? (
							<div className="h-100 w-100 d-flex align-items-center justify-content-center">
								<FontAwesomeIcon
									icon={["fas", "spinner-third"]}
									className="fa-spin mr-2"
								/>{" "}
								Taking a trip down memory lane...
							</div>
						) : (
							<div className="h-100 w-100 d-flex align-items-center justify-content-center">
								<div className="text-center">
									<img
										src={Logo}
										style={{ width: "30%" }}
										alt="brain"
										className="animated infinite bounce"
									/>
									<br />
									Searching your digital brain for matches...
								</div>
							</div>
						)
					) : (
						<MemorySection
							memories={this.state.memories}
							isLoadingMore={this.state.loadMore}
							noMore={this.state.anyMore}
							highlight={this.state.hasSearched}
							searchTerm={this.state.searchTerm}
						/>
					)}
				</div>
			</div>
		);
	}
}

export default SearchPage;
