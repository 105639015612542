/*
 * @Author: Matthew Auld
 * @Date: 2019-02-21 01:30:06
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-02-21 12:07:13
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./settings-page.scss";
import { FILE_URL } from "../../config";
import SubPageHeader from "../../components/SubPageHeader";
import { AUTH, API } from "../../API";
import logo from "../../logo.svg";

class SettingsPage extends Component {
	_isMounted = false;
	state = {
		full_name: "Loading...",
		join_date: "",
		photo: null,
		plan: "basic"
	};

	componentDidMount() {
		this._isMounted = true;

		let u = AUTH.getUserData();
		let photo = null;
		if (u.user_photo_path !== null) {
			photo = FILE_URL + u.user_photo_path;
		}

		this.setState({
			full_name: u.user_given_name + " " + u.user_family_name,
			join_date: API.formatDate(u.created_at),
			photo
		});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	render() {
		return (
			<div id="settings-page" className="settings-page">
				<SubPageHeader
					icon={["fas", "cogs"]}
					text="Settings"
					afterElements={
						<div style={{ width: 32, marginRight: ".5rem" }} />
					}
				/>
				<div id="settings-scroll">
					<div id="settings-header">
						<button
							className={
								"photo" +
								(this.state.photo !== null ? "" : " no-photo")
							}
							style={
								this.state.photo !== null
									? {
											backgroundImage:
												"url('" +
												this.state.photo +
												"')"
									  }
									: { backgroundImage: 'url("' + logo + '")' }
							}
						/>
						<h1>{this.state.full_name}</h1>
						<p>{this.state.join_date}</p>
					</div>
					<div
						id="plan-details"
						className={this.state.plan + "-plan"}
					>
						<button className="">
							<span>{this.state.plan}</span>
							<span>(Upgrade Your Plan)</span>
						</button>
						{this.state.plan.toLowerCase() === "basic" ? (
							<p>
								You're on the basic plan. <b>FREE FOREVER!</b>
							</p>
						) : (
							<p>
								You have been a <b>{this.state.plan}</b> for{" "}
								<span>0</span> days.
							</p>
						)}
					</div>
					<ul id="menu-items">
						<li>
							<Link to="settings/plan-information">
								Plan Information
							</Link>
						</li>
						<li>
							<Link to="settings/general">General</Link>
						</li>
						<li>
							<Link to="settings/profile">Profile</Link>
						</li>
						<li className="disabled upgrade-required">
							<Link to="#">Passcode</Link>
						</li>
						<li className="disabled upgrade-required">
							<Link to="#">Storage</Link>
						</li>
						<li className="disabled">
							<Link to="#">Legal</Link>
						</li>
					</ul>
				</div>
			</div>
		);
	}
}

export default SettingsPage;
