/*
 * @Author: Matthew Auld
 * @Date: 2019-02-09 06:26:29
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-02-09 11:37:02
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import LandingHeader from "../components/LandingHeader";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import $ from "jquery";
import "./register-page.scss";
import { USER } from "../API";

class RegisterPage extends Component {
	_data = {};
	state = { step: 0, redirect: false };

	showNext() {
		this._data.user_email = $("#email").val();
		this._data.password = $("#password").val();

		this.setState({
			step: this.state.step + 1
		});
	}

	submitRegistration(e) {
		e.preventDefault();
		let data = { ...this._data };
		data.user_given_name = $("#first-name").val();
		data.user_family_name = $("#last-name").val();
		data.user_dob = Math.floor(new Date($("#dob").val()).getTime() / 1000);

		USER.register(data, json => {
			if (json.success) {
				this.setState({
					redirect: true
				});
			}
		});
	}

	render() {
		if (this.state.redirect) return <Redirect to="/login" />;

		return (
			<div id="register-page" className="pt-5 landing-page">
				<LandingHeader />
				<form
					onSubmit={() => {
						return false;
					}}
					className="pt-5"
				>
					{this.state.step === 0 && (
						<Fragment>
							<div className="row">
								<div className="col-12">
									<input
										type="email"
										placeholder="Email Address"
										className="form-control mb-2"
										id="email"
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<input
										type="password"
										placeholder="Password"
										className="form-control mb-2"
										id="password"
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<input
										type="password"
										placeholder="Confirm Password"
										className="form-control mb-2"
										id="confirm-password"
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<button
										className="btn btn-me btn-block mb-2"
										onClick={this.showNext.bind(this)}
									>
										Next
									</button>
								</div>
							</div>
						</Fragment>
					)}
					{this.state.step === 1 && (
						<Fragment>
							<div className="row">
								<div className="col-12">
									<DayPickerInput
										placeholder="Date of Birth"
										id="last-name"
										inputProps={{
											className: "form-control mb-2",
											id: "dob"
										}}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<input
										type="text"
										placeholder="First Name"
										className="form-control mb-2"
										id="first-name"
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<input
										type="text"
										placeholder="Last Name"
										className="form-control mb-2"
										id="last-name"
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<button
										className="btn btn-me btn-block mb-2"
										onClick={this.submitRegistration.bind(
											this
										)}
									>
										Register
									</button>
								</div>
							</div>
						</Fragment>
					)}
				</form>
				<div className="bottom">
					<Link to="/login" className="btn btn-block btn-me-land">
						Already have an account? Sign In
					</Link>
				</div>
			</div>
		);
	}
}

export default RegisterPage;
