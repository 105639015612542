/*
 * @Author: Matthew Auld
 * @Date: 2019-02-21 05:33:37
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-02-21 06:03:56
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import SubPageHeader from "../../../components/SubPageHeader";
import "../settings-page.scss";

class PlanInformationSettingsPage extends Component {
	state = {
		plan_name: "Basic"
	};
	render() {
		let relative = this.props.location.pathname;
		let back = relative.substring(0, relative.lastIndexOf("/"));

		return (
			<div id="plan-information" className="settings-page">
				<SubPageHeader
					icon={["fal", "chevron-left"]}
					className={this.state.plan_name.toLowerCase() + "-plan"}
					text={this.state.plan_name}
					backLink={back}
					afterElements={
						<div style={{ width: 32, marginRight: ".5rem" }} />
					}
				/>
				<ul id="menu-items">
					<li />
				</ul>
			</div>
		);
	}
}

export default PlanInformationSettingsPage;
