import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
// import "animate.css/animate.css";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { faChevronLeft as faLightChevronLeft } from "@fortawesome/pro-light-svg-icons";
import {
	faBrain,
	faHome,
	faSearch,
	faCogs,
	faClipboardList,
	faHeadset,
	faPaperPlane,
	faQuestion,
	faCameraRetro,
	faSpinnerThird,
	faChevronLeft,
	faChevronDown,
	faChevronUp,
	faUser,
	faPhone,
	faEnvelope,
	faPencil,
	faSave
} from "@fortawesome/pro-solid-svg-icons";

import { library } from "@fortawesome/fontawesome-svg-core";

library.add(
	faBrain,
	faHome,
	faSearch,
	faCogs,
	faClipboardList,
	faHeadset,
	faPaperPlane,
	faQuestion,
	faCameraRetro,
	faSpinnerThird,
	faChevronLeft,
	faChevronDown,
	faChevronUp,
	faLightChevronLeft,
	faUser,
	faPhone,
	faEnvelope,
	faPencil,
	faSave
);

ReactDOM.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
