/*
 * @Author: Matthew Auld
 * @Date: 2019-02-09 06:17:40
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-02-09 12:09:43
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import "./login-page.scss";
import LandingHeader from "../components/LandingHeader";
import $ from "jquery";
import { USER, AUTH } from "../API";
import Swal from "sweetalert2";

class LoginPage extends Component {
	state = { redirect: false };

	attemptLogin(e) {
		e.preventDefault();

		let email = $("#email").val();
		let password = $("#password").val();

		USER.login(email, password, json => {
			if (!json.success) {
				Swal.fire({
					title: "Failed To Login",
					text: json.message,
					type: "error"
				});
			} else {
				AUTH.setUserData(json);
				this.setState({
					redirect: true
				});
				this.props.onLogin(true);
			}
		});
	}

	render() {
		if (this.state.redirect) return <Redirect to="/" />;

		return (
			<div id="login-page" className="pt-5 landing-page">
				<LandingHeader />
				<form
					onSubmit={() => {
						return false;
					}}
					className="pt-5"
				>
					<div className="row">
						<div className="col-12">
							<input
								type="email"
								placeholder="Email Address"
								className="form-control mb-2"
								id="email"
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<input
								type="password"
								placeholder="Password"
								className="form-control mb-2"
								id="password"
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<button
								className="btn btn-me btn-block mb-2"
								onClick={this.attemptLogin.bind(this)}
							>
								Sign In
							</button>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<Link
								to="/forgot-password"
								className="btn btn-block btn-link text-white"
							>
								Forgot your password?
							</Link>
						</div>
					</div>
				</form>
				<div className="bottom">
					<Link to="/register" className="btn btn-block btn-me-land">
						Don't have an account? Sign Up
					</Link>
				</div>
			</div>
		);
	}
}

export default LoginPage;
