/*
 * @Author: Matthew Auld
 * @Date: 2019-02-09 15:42:04
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-02-21 12:12:11
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import "./MemorySection.scss";
import MemoryPreview from "./MemoryPreview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class MemorySection extends Component {
	_isMount = false;
	state = {
		memories: [],
		isLoadingMore: false,
		noMore: false,
		highlight: false,
		searchTerm: null
	};

	componentDidMount() {
		this._isMount = true;
		if (this._isMount)
			this.setState({
				memories: this.props.memories,
				noMore: this.props.noMore,
				searchTerm: this.props.searchTerm
			});
	}

	componentWillReceiveProps(nextProps) {
		let {
			memories,
			isLoadingMore,
			noMore,
			highlight,
			searchTerm
		} = this.state.memories;

		if (nextProps.hasOwnProperty("isLoadingMore")) {
			if (this.state.isLoadingMore !== nextProps.isLoadingMore) {
				isLoadingMore = nextProps.isLoadingMore;
			}
		}

		if (nextProps.hasOwnProperty("memories")) {
			if (memories !== nextProps.memories) {
				memories = nextProps.memories;
			}
		}

		if (nextProps.hasOwnProperty("noMore")) {
			if (noMore !== nextProps.noMore) {
				noMore = nextProps.noMore;
			}
		}

		if (nextProps.hasOwnProperty("highlight")) {
			if (highlight !== nextProps.highlight) {
				highlight = nextProps.highlight;
			}
		}

		if (nextProps.hasOwnProperty("searchTerm")) {
			if (searchTerm !== nextProps.searchTerm) {
				searchTerm = nextProps.searchTerm;
			}
		}

		this.setState({
			isLoadingMore,
			memories,
			noMore,
			highlight,
			searchTerm
		});
	}

	componentWillUnmount() {
		this._isMount = false;
	}

	render() {
		return (
			<div id="memory-section" className="w-100">
				{this.state.memories.map((m, k) => (
					<MemoryPreview
						key={k}
						delay={m.delay * 500}
						memory={m}
						highlight={this.state.highlight}
						searchTerm={this.state.searchTerm}
					/>
				))}

				{this.state.isLoadingMore && !this.state.noMore && (
					<div className="d-flex align-items-center justify-content-center py-3">
						<FontAwesomeIcon
							icon={["fas", "spinner-third"]}
							className="fa-spin mr-2"
						/>{" "}
						Loading More Memories...
					</div>
				)}

				{this.state.noMore && (
					<div className="d-flex align-items-center justify-content-center py-3">
						<p
							style={{ fontSize: 12 }}
							className="m-0 px-3 text-center"
						>
							You've reached the beginning of your journey.
							<br />
							You don't have any more ME.mories to load.
						</p>
					</div>
				)}
			</div>
		);
	}
}

export default MemorySection;
