/*
 * @Author: Matthew Auld
 * @Date: 2019-02-09 11:46:48
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-02-09 15:29:15
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Logo from "../logo.svg";
import "./nav-page.scss";
import { USER } from "../API";
import NavigationButton from "../components/NavigationButton";

class NavPage extends Component {
	state = { goto: null };

	gotoPage(e, page) {
		this.setState({
			goto: e
		});
	}

	render() {
		if (this.state.goto !== null) return <Redirect to={this.state.goto} />;

		return (
			<div id="home-page">
				<div className="filler">
					<div className="row justify-content-center">
						<div className="col-4 d-flex align-items-end justify-content-center">
							<NavigationButton
								icon={["fas", "brain"]}
								onClick={this.gotoPage.bind(this, "/make")}
							/>
						</div>
						<div className="col-4 d-flex align-items-end justify-content-center">
							<NavigationButton
								icon={["fas", "home"]}
								className="mb-5"
								onClick={this.gotoPage.bind(this, "/home")}
							/>
						</div>
						<div className="col-4 d-flex align-items-end justify-content-center">
							<NavigationButton
								icon={["fas", "search"]}
								onClick={this.gotoPage.bind(this, "/search")}
							/>
						</div>
					</div>
					<div className="row align-items-center justify-content-center">
						<div className="text-center">
							<img
								src={Logo}
								alt="MEmory Logo"
								style={{ width: "70%" }}
							/>
							<h1 className="logo-text">
								<span>ME</span>
								<span>.mory</span>
							</h1>
						</div>
					</div>
					<div className="row">
						<div className="col-4 d-flex align-items-start justify-content-center">
							<NavigationButton
								icon={["fas", "cogs"]}
								onClick={this.gotoPage.bind(this, "/settings")}
							/>
						</div>
						<div className="col-4 d-flex align-items-start justify-content-center">
							<NavigationButton
								icon={["fas", "clipboard-list"]}
								className="mt-5"
								disabled
							/>
						</div>
						<div className="col-4 d-flex align-items-start justify-content-center">
							<NavigationButton
								icon={["fas", "headset"]}
								disabled
							/>
						</div>
					</div>
				</div>
				<div className="bottom">
					<Link
						to="/logout"
						className="btn btn-me-land light btn-block"
					>
						Logout<small>({USER.getEmail()})</small>
					</Link>
				</div>
			</div>
		);
	}
}

export default NavPage;
