/*
 * @Author: Matthew Auld
 * @Date: 2019-02-21 03:32:29
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-02-21 04:58:24
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import { app_version } from "../../../config";
import SubPageHeader from "../../../components/SubPageHeader";
import "../settings-page.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AUTH, USER, API } from "../../../API";

class AboutGeneralSettingsPage extends Component {
	_isMounted = false;
	state = {
		full_name: null,
		plan: null,
		memory_count: null,
		photo_count: null,
		video_count: null,
		capacity: null,
		used: null,
		available: null,
		audio_count: null
	};

	componentDidMount() {
		this._isMounted = true;

		let localData = AUTH.getUserData();

		USER.getAboutSettingsStats(json => {
			if (json.success) {
				console.log(json);
				if (this._isMounted)
					this.setState({
						plan: json.stats.plan_name,
						memory_count: json.stats.total_memories,
						photo_count: json.stats.photos,
						video_count: 0,
						audio_count: 0,
						used: json.stats.used,
						capacity: json.stats.capacity,
						available: json.stats.capacity - json.stats.used
					});
			}
		});

		if (this._isMounted)
			this.setState({
				full_name:
					localData.user_given_name + " " + localData.user_family_name
			});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	render() {
		let relative = this.props.location.pathname;
		let back = relative.substring(0, relative.lastIndexOf("/"));

		return (
			<div id="about-general-settings" className="settings-page">
				<SubPageHeader
					icon={["fal", "chevron-left"]}
					text="About"
					backLink={back}
					afterElements={
						<div style={{ width: 32, marginRight: ".5rem" }} />
					}
				/>
				<ul id="menu-items" className="basic">
					<li>
						Full Name
						{this.state.full_name !== null ? (
							<span>{this.state.full_name}</span>
						) : (
							<span>
								<FontAwesomeIcon
									icon={["fas", "spinner-third"]}
									className="fa-spin"
								/>
							</span>
						)}
					</li>
					<li>
						Plan
						{this.state.plan !== null ? (
							<span
								className={
									this.state.plan.toLowerCase() + "-plan"
								}
							>
								{this.state.plan.replace(/\w\S*/g, function(
									txt
								) {
									return (
										txt.charAt(0).toUpperCase() +
										txt.substr(1).toLowerCase()
									);
								})}
							</span>
						) : (
							<span>
								<FontAwesomeIcon
									icon={["fas", "spinner-third"]}
									className="fa-spin"
								/>
							</span>
						)}
					</li>
					<li>
						Total ME.mories
						{this.state.memory_count !== null ? (
							<span>
								{API.formatNumber(this.state.memory_count)}
							</span>
						) : (
							<span>
								<FontAwesomeIcon
									icon={["fas", "spinner-third"]}
									className="fa-spin"
								/>
							</span>
						)}
					</li>
					<li>
						Photos
						{this.state.photo_count !== null ? (
							<span>
								{API.formatNumber(this.state.photo_count)}
							</span>
						) : (
							<span>
								<FontAwesomeIcon
									icon={["fas", "spinner-third"]}
									className="fa-spin"
								/>
							</span>
						)}
					</li>
					<li>
						Videos
						{this.state.video_count !== null ? (
							<span>
								{API.formatNumber(this.state.video_count)}
							</span>
						) : (
							<span>
								<FontAwesomeIcon
									icon={["fas", "spinner-third"]}
									className="fa-spin"
								/>
							</span>
						)}
					</li>
					<li>
						Audio Clips
						{this.state.audio_count !== null ? (
							<span>
								{API.formatNumber(this.state.audio_count)}
							</span>
						) : (
							<span>
								<FontAwesomeIcon
									icon={["fas", "spinner-third"]}
									className="fa-spin"
								/>
							</span>
						)}
					</li>
					<li>
						Capacity
						{this.state.capacity !== null ? (
							<span>{API.formatBytes(this.state.capacity)}</span>
						) : (
							<span>
								<FontAwesomeIcon
									icon={["fas", "spinner-third"]}
									className="fa-spin"
								/>
							</span>
						)}
					</li>
					<li>
						Used
						{this.state.used !== null ? (
							<span>{API.formatBytes(this.state.used)}</span>
						) : (
							<span>
								<FontAwesomeIcon
									icon={["fas", "spinner-third"]}
									className="fa-spin"
								/>
							</span>
						)}
					</li>
					<li>
						Available
						{this.state.available !== null ? (
							<span>{API.formatBytes(this.state.available)}</span>
						) : (
							<span>
								<FontAwesomeIcon
									icon={["fas", "spinner-third"]}
									className="fa-spin"
								/>
							</span>
						)}
					</li>
					<li>
						Version<span>{app_version}</span>
					</li>
				</ul>
			</div>
		);
	}
}

export default AboutGeneralSettingsPage;
