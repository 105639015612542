/*
 * @Author: Matthew Auld
 * @Date: 2019-02-09 13:00:50
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-02-09 13:24:30
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import "./NavigationButton.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class NavigationButton extends Component {
	state = {};
	render() {
		return (
			<button
				className={
					"btn-diamond " +
					(this.props.hasOwnProperty("className")
						? this.props.className
						: "")
				}
				disabled={this.props.hasOwnProperty("disabled") && "disabled"}
				onClick={
					this.props.hasOwnProperty("onClick")
						? this.props.onClick
						: () => {}
				}
			>
				<div className="center-block">
					<FontAwesomeIcon icon={this.props.icon} className="icon" />
				</div>
			</button>
		);
	}
}

export default NavigationButton;
