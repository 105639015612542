/*
 * @Author: Matthew Auld
 * @Date: 2019-02-21 05:27:59
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-02-21 10:40:27
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import SubPageHeader from "../../../components/SubPageHeader";
import "../settings-page.scss";

class DateTimeGeneralSettingsPage extends Component {
	state = {
		timezone_text: "Toronto (EST)"
	};
	render() {
		let relative = this.props.location.pathname;
		let back = relative.substring(0, relative.lastIndexOf("/"));

		return (
			<div id="date-time-settings" className="settings-page">
				<SubPageHeader
					icon={["fal", "chevron-left"]}
					text="Date &amp; Time"
					backLink={back}
					afterElements={
						<div style={{ width: 32, marginRight: ".5rem" }} />
					}
				/>
				<ul id="menu-items">
					<li className="disabled border-0" />
					<li className="disabled">
						<button>
							Time Zone
							<span>{this.state.timezone_text}</span>
						</button>
					</li>
				</ul>
			</div>
		);
	}
}

export default DateTimeGeneralSettingsPage;
