/*
 * @Author: Matthew Auld
 * @Date: 2019-02-09 15:24:38
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-02-21 12:11:00
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import "./home-page.scss";
import PageHeader from "../../components/SubPageHeader";
import MemorySection from "../../components/MemorySection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MEMORIES } from "../../API";

class HomePage extends Component {
	_isMounted = false;
	state = {
		memories: []
	};

	componentDidMount() {
		this._isMounted = true;
		if (localStorage.getItem("home_memories") === null) {
			MEMORIES.loadHome(5, json => {
				if (json.success) {
					localStorage.setItem(
						"home_memories",
						window.btoa(JSON.stringify(json.memories))
					);
					if (this._isMounted)
						this.setState({
							memories: json.memories
						});
				}
			});
		} else {
			let existing = JSON.parse(
				window.atob(localStorage.getItem("home_memories"))
			);
			this.setState({
				memories: existing
			});
			MEMORIES.loadHome(5, json => {
				if (json.memories !== existing) {
					localStorage.setItem(
						"home_memories",
						window.btoa(JSON.stringify(json.memories))
					);
					let memories = [];

					memories = [...json.memories];
					if (this._isMounted)
						this.setState({
							memories
						});
				}
			});
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	render() {
		return (
			<div id="home-page">
				<PageHeader
					text="ME.mory Entries"
					icon={["fas", "home"]}
					afterElements={
						<div style={{ width: 32, marginRight: ".5rem" }} />
					}
				/>
				<div className="page-rest scrollable">
					<div className="header-remainders">
						<div className="text-center">
							<div className="btn-group mx-auto mb-3 mt-1">
								<button
									type="button"
									className="btn btn-sm btn-outline-light"
								>
									Day
								</button>
								<button
									type="button"
									className="btn btn-sm btn-outline-light"
								>
									Week
								</button>
								<button
									type="button"
									className="btn btn-sm btn-outline-light"
								>
									Month
								</button>
								<button
									type="button"
									className="btn btn-sm btn-outline-light"
								>
									Year
								</button>
							</div>
						</div>
					</div>
					<div
						className="row w-100 mx-0"
						style={
							this.state.memories !== null &&
							this.state.memories.length > 0
								? { flex: "1 1 auto" }
								: { flex: "1 1 100%" }
						}
					>
						{this.state.memories !== null &&
						this.state.memories.length > 0 ? (
							<MemorySection memories={this.state.memories} />
						) : (
							<div
								className="d-flex align-items-center justify-content-center w-100"
								style={{ backgroundColor: "#dedede" }}
							>
								<FontAwesomeIcon
									icon={["fas", "spinner-third"]}
									className="fa-spin mr-3"
								/>
								Loading Memories...
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default HomePage;
