/*
 * @Author: Matthew Auld
 * @Date: 2019-02-09 07:27:37
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-02-21 10:56:06
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import { API_URL } from "./config.js";

// const DAYS = [
// 	"Sunday",
// 	"Monday",
// 	"Tuesday",
// 	"Wednesday",
// 	"Thursday",
// 	"Friday",
// 	"Saturday"
// ];
const MONTHS = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December"
];

const DEFAULT_JSON = {
	success: false,
	error: "Invalid JSON Response"
};

/**
 * @class Api
 */
class Api {
	parseISO8601(dateStringInRange, time) {
		var isoExp = /^\s*(\d{4})-(\d\d)-(\d\d)\s*$/,
			date = new Date(NaN),
			month,
			parts = isoExp.exec(dateStringInRange);

		if (parts) {
			month = +parts[2];
			date.setFullYear(parts[1], month - 1, parts[3]);
			if (month !== date.getMonth() + 1) {
				date.setTime(NaN);
			}
			let t = time.split(":");
			date.setHours(t[0]);
			date.setMinutes(t[1]);
			date.setSeconds(t[2]);
		}
		return date;
	}

	formatDate(dateString) {
		let dt;
		if (typeof dateString === "string" && dateString.indexOf(" ") >= 0) {
			let dtParts = dateString.split(" ");
			dt = this.parseISO8601(dtParts[0], dtParts[1]);
		} else {
			dt = new Date(dateString);
		}

		let date = dt.getDate();

		let hour = dt.getHours();
		hour = hour >= 13 ? hour - 12 : hour;

		let minute =
			dt.getMinutes() <= 9 ? "0" + dt.getMinutes() : dt.getMinutes();

		let ampm = dt.getHours() >= 12 ? "PM" : "AM";

		return (
			MONTHS[dt.getMonth()] +
			" " +
			date +
			this.ordinal(parseInt(date)) +
			", " +
			dt.getFullYear() +
			" at " +
			hour +
			":" +
			minute +
			" " +
			ampm
		);
	}

	ordinal(n) {
		return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
	}

	/**
	 * @description Converts an object of key=>value parameters to URL safe string.
	 * @param {object} params An object to be converted to a URL query string.
	 * @returns {string} URL ready <i><b>query_string</b></i>
	 */
	formatParams(params = {}) {
		if (params === null || params.length === 0) return "";
		return (
			"?" +
			Object.keys(params)
				.map(function(key) {
					return key + "=" + encodeURIComponent(params[key]);
				})
				.join("&")
		);
	}

	formatNumber(n) {
		return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	formatBytes(b) {
		let exts = ["bytes", "KB", "MB", "GB", "TB", "PB"];
		let type = 0;
		while (b >= 1000) {
			type++;
			b = b / 1000;
		}

		return b.toFixed(2) + " " + exts[type];
	}

	/**
	 * @description Check if the passed <i><b>json_string</b></i> variable is valid JSON text that can be parsed.
	 * @param {string} json_string JSON data in <i>string</i> format.
	 * @returns {boolean}
	 */
	isJSON(json_string) {
		if (json_string === null || json_string === undefined) return false;
		if (
			/^[\],:{}\s]*$/.test(
				json_string
					.toString()
					.replace(/\\["\\\\/bfnrtu]/g, "@")
					.replace(
						/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\\-]?\d+)?/g,
						"]"
					)
					.replace(/(?:^|:|,)(?:\s*\[)+/g, "")
			)
		) {
			return true;
		}
		return false;
	}
}

/**
 * @class Api.User
 */
Api.User = class {
	/**
	 * Attempt to login from the API.
	 * @param {string} email
	 * @param {string} password
	 * @param {function} callback
	 */
	login(email, password, callback) {
		WEB.post(
			API_URL + "user/login",
			{ user_email: email, password },
			callback
		);
	}

	/**
	 *
	 * @param {object} registration_data
	 * @param {function} callback
	 */
	register(registration_data, callback) {
		WEB.post(API_URL + "user/register", registration_data, callback);
	}

	getEmail() {
		let data = AUTH.getUserData();

		if (data) {
			return data.user_email;
		} else {
			return "";
		}
	}

	getAboutSettingsStats(callback) {
		WEB.get(API_URL + "user/about_settings", callback, {});
	}

	saveProfileDetails(data, callback) {
		WEB.post(API_URL + "user/save_profile", data, callback);
	}

	uploadProfilePhoto(data, callback, progress) {
		WEB.postWithProgress(
			API_URL + "user/upload_profile_photo",
			data,
			progress,
			callback
		);
	}
};

/**
 * @class API.Auth
 * All API calls and front-end functions for Authentication Systems.
 */
Api.Auth = class AUTH {
	getUserData() {
		let data = localStorage.getItem("user");
		return data !== undefined ? JSON.parse(data) : false;
	}

	/**
	 * Checks if the browser has an <i><b>access_token</b></i> saved in <i><b>localStorage</b></i> or as a <i><b>COOKIE</b></i>.
	 */
	hasAccessToken() {
		let token = this.getAccessToken();
		if (token !== null && token !== undefined) return true;
		return false;
	}

	/**
	 * Check if the current <codeblock>access_token</codeblock> is valid.
	 * @param {function} [cb] Callback function with returned JSON data.
	 */
	isAccessTokenValid(cb = null) {
		WEB.post(
			API_URL + "user/validate_token",
			{},
			cb,
			{},
			this.getAccessToken(),
			cb
		);
	}

	/**
	 * Get the value of the current <i><b>access_token</b></i>. Returns <i><b>NULL</b></i> if there is no token.
	 * @returns {string|null}
	 */
	getAccessToken() {
		let lS = localStorage.getItem("access_token");
		if (lS !== null && lS !== undefined) return lS;
		return null;
	}

	/**
	 * Completely wipes out the <i><b>access_token</b></i> from the users browser: <i><b>Cookie</b></i> and <i><b>localStorage</b></i>.
	 */
	clearAccessToken() {
		localStorage.clear();
	}

	updateUserData(json_data) {
		localStorage.setItem("user", JSON.stringify(json_data.user));
	}

	setUserData(json_data) {
		localStorage.setItem("user", JSON.stringify(json_data.user));
		localStorage.setItem("access_token", json_data.access_token);
	}
};

Api.Memories = class {
	loadHome(count, callback) {
		WEB.get(API_URL + "memories/load_home", callback, { count });
	}

	loadDetails(memory_id, callback) {
		WEB.get(API_URL + "memories/get_memory_details", callback, {
			memory_id
		});
	}

	loadPage(page_number, callback, limit = 25) {
		WEB.get(API_URL + "memories/get_page", callback, {
			page_number,
			limit
		});
	}

	searchMEmories(query, callback) {
		WEB.post(API_URL + "memories/search", { query }, callback);
	}

	createMemory(data, callback) {
		WEB.post(API_URL + "memories/create_memory", data, callback);
	}

	attachPhoto(memory_id, file, progress, callback) {
		WEB.postWithProgress(
			API_URL + "memories/attach_photo",
			{ memory_id, file },
			progress,
			callback
		);
	}

	deleteMemory(memory_id, callback) {
		WEB.post(API_URL + "memories/delete_memory", { memory_id }, callback);
	}
};

class Web {
	/**
	 * The same function as get but without an "Accept: application/json".
	 * @param {string} url URL you'd like to load JSON data from.
	 * @param {function} cb The callback function that will be used to handle the returned JSON data.
	 * @param {object} [params] Another object of <i><b>key=>value</b></i> data that will be used for as the <i><b>query_string</b></i>. <i>(OPTIONAL)</i>
	 * @param {string} [auth_token] A manual override to the <i><b>access_token</b></i> information. <i>(OPTIONAL)</i>
	 */
	getRaw(url, cb, params = {}, auth_token = AUTH.getAccessToken()) {
		let h = new XMLHttpRequest();

		h.onreadystatechange = function() {
			if (
				(h.readyState === 4 && h.status === 200) ||
				(h.readyState === 4 && h.status === 401)
			) {
				let data = this.responseText;
				if (typeof cb === "function") {
					cb(data);
				}
			}
		};

		h.open("GET", url + API.formatParams(params), true);

		if (auth_token !== null) {
			h.setRequestHeader("Authorization", `Bearer ${auth_token}`);
		}
		h.send();
	}

	/**
	 *
	 * @param {string} url URL you'd like to load JSON data from.
	 * @param {function} cb The callback function that will be used to handle the returned JSON data.
	 * @param {object} [params] Another object of <i><b>key=>value</b></i> data that will be used for as the <i><b>query_string</b></i>. <i>(OPTIONAL)</i>
	 * @param {string} [auth_token] A manual override to the <i><b>access_token</b></i> information. <i>(OPTIONAL)</i>
	 */
	get(url, cb, params = {}, auth_token = AUTH.getAccessToken()) {
		let h = new XMLHttpRequest();

		h.onreadystatechange = function() {
			if (
				(h.readyState === 4 && h.status === 200) ||
				(h.readyState === 4 && h.status === 401)
			) {
				let data = this.responseText;
				let json = DEFAULT_JSON;
				if (API.isJSON(data)) json.error = null;
				json = Object.assign({}, json, JSON.parse(data));
				if (typeof cb === "function") {
					cb(json);
				}
			}
		};

		h.open("GET", url + API.formatParams(params), true);

		if (auth_token !== null) {
			h.setRequestHeader("Authorization", `Bearer ${auth_token}`);
		}
		h.setRequestHeader("Accept", "application/json");
		h.send();
	}

	/**
	 * Submit <b>FormData</b> to a url via POST.
	 * @param {string} url URL you'd like to get JSON data from after submitting <i>data</i>.
	 * @param {object} data The formData you'd like to submit in <i><b>key=>value</b></i> format.
	 * @param {function} cb The callback function that will be used to handle the returned JSON data.
	 * @param {object} [params] Another object of <i><b>key=>value</b></i> data that will be used for as the <i><b>query_string</b></i>. <i>(OPTIONAL)</i>
	 * @param {string} [auth_token] A manual override to the <i><b>access_token</b></i> information. <i>(OPTIONAL)</i>
	 */
	post(
		url,
		data,
		cb = null,
		params = {},
		auth_token = AUTH.getAccessToken(),
		errorHandler
	) {
		let h = new XMLHttpRequest();

		let formData = new FormData();
		for (var key in data) {
			formData.append(key, data[key]);
		}

		h.onreadystatechange = function() {
			if (h.readyState === 4 && h.status === 200) {
				let data = this.responseText;
				let json = DEFAULT_JSON;
				if (API.isJSON(data)) json.error = null;
				json = Object.assign({}, json, JSON.parse(data));
				if (typeof cb === "function") {
					cb(json);
				}
			} else if (h.readyState === 4 && h.status === 401) {
				let data = this.responseText;
				let json = DEFAULT_JSON;
				if (API.isJSON(data)) json.error = null;
				json = Object.assign({}, json, JSON.parse(data));
				if (typeof errorHandler === "function") {
					errorHandler(json);
				}
			}
		};

		h.open("POST", url + API.formatParams(params), true);

		if (auth_token !== null) {
			h.setRequestHeader("Authorization", `Bearer ${auth_token}`);
		}
		h.setRequestHeader("Accept", "application/json");
		h.send(formData);
	}

	/**
	 * Submit <b>FormData</b> to a url via POST.
	 * @param {string} url URL you'd like to get JSON data from after submitting <i>data</i>.
	 * @param {object} data The formData you'd like to submit in <i><b>key=>value</b></i> format.
	 * @param {function|null} [progress] This callback function is used for getting upload percentages. DEFAULT: `null`
	 * @param {function|null} cb The callback function that will be used to handle the returned JSON data.
	 * @param {object} [params] Another object of <i><b>key=>value</b></i> data that will be used for as the <i><b>query_string</b></i>. <i>(OPTIONAL)</i>
	 * @param {string} [auth_token] A manual override to the <i><b>access_token</b></i> information. <i>(OPTIONAL)</i>
	 */
	postWithProgress(
		url,
		data,
		progress = null,
		cb = null,
		params = {},
		auth_token = AUTH.getAccessToken()
	) {
		let h = new XMLHttpRequest();

		let formData = new FormData();
		for (var key in data) {
			formData.append(key, data[key]);
		}

		h.onreadystatechange = function() {
			if (h.readyState === 4 && h.status === 200) {
				let data = this.responseText;
				let json = DEFAULT_JSON;
				if (API.isJSON(data)) json.error = null;
				json = Object.assign({}, json, JSON.parse(data));
				if (typeof cb === "function") {
					cb(json);
				}
			}
		};

		h.upload.onloadstart = function(e) {
			if (typeof progress === "function") progress(false, 0);
		};

		h.upload.onprogress = function(e) {
			if (e.lengthComputable) {
				if (typeof progress === "function") progress(true, e);
			}
		};

		h.upload.onloadend = function(e) {
			if (e.lengthComputable) {
				if (typeof progress === "function") progress(false, e.loaded);
			}
		};

		h.open("POST", url + API.formatParams(params), true);

		if (auth_token !== null) {
			h.setRequestHeader("Authorization", `Bearer ${auth_token}`);
		}
		h.setRequestHeader("Accept", "application/json");
		h.send(formData);
	}
}

let WEB = new Web();
export let API = new Api();
export let USER = new Api.User();
export let AUTH = new Api.Auth();
export let MEMORIES = new Api.Memories();
