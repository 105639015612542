/*
 * @Author: Matthew Auld
 * @Date: 2019-02-21 10:30:06
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-02-21 10:39:04
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import SubPageHeader from "../../../components/SubPageHeader";
import "../settings-page.scss";

class MemoriesGeneralSettingsPage extends Component {
	_isMounted = false;
	state = {};

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	render() {
		let relative = this.props.location.pathname;
		let back = relative.substring(0, relative.lastIndexOf("/"));
		return (
			<div id="memories-general-settings" className="settings-page">
				<SubPageHeader
					icon={["fal", "chevron-left"]}
					text="ME.mories"
					backLink={back}
					afterElements={
						<div style={{ width: 32, marginRight: ".5rem" }} />
					}
				/>
				<ul id="menu-items">
					<li className="disabled border-0" />
					<li>
						<button>
							Homepage
							<span>5 Entries</span>
						</button>
					</li>
					<li className="disabled">
						<p className="description text-center">
							Choose how many ME.mories you want to show on the
							homepage
						</p>
					</li>
					<li className="disabled">
						Title Suggestions
						<label className="switch">
							<input type="checkbox" disabled="disabled" />
							<span className="slider round" />
						</label>
					</li>
					<li className="disabled">
						Search Suggestions
						<label className="switch">
							<input type="checkbox" disabled="disabled" />
							<span className="slider round" />
						</label>
					</li>
					<li className="disabled">
						Tag Suggestions
						<label className="switch">
							<input type="checkbox" disabled="disabled" />
							<span className="slider round" />
						</label>
					</li>
					<li className="disabled">
						Audio Auto-Play
						<label className="switch">
							<input type="checkbox" disabled="disabled" />
							<span className="slider round" />
						</label>
					</li>
				</ul>
			</div>
		);
	}
}

export default MemoriesGeneralSettingsPage;
