/*
 * @Author: Matthew Auld
 * @Date: 2019-02-21 05:46:03
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-02-21 12:06:59
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component, Fragment } from "react";
import NumberFormat from "react-number-format";
import SubPageHeader from "../../../components/SubPageHeader";
import "../settings-page.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../../logo.svg";
import { AUTH, USER, API } from "../../../API";
import $ from "jquery";
import { FILE_URL } from "../../../config";
import EXIF from "exif-js";

class ProfileSettingsPage extends Component {
	_isMounted = false;
	state = {
		full_name: null,
		phone_number: null,
		email: null,
		photo: null,
		join_date: null,
		editEmail: null,
		editPhone: null,
		editFirstName: null,
		editLastName: null,
		showEditForm: false,
		changedPhoto: false,
		isSaving: false,
		file: null,
		isUploading: false,
		uploadProgress: "0%"
	};

	componentDidMount() {
		this._isMounted = true;

		let u = AUTH.getUserData();

		let photo = null;

		if (u.user_photo_path !== null) {
			photo = FILE_URL + u.user_photo_path;
		}

		if (this._isMounted)
			this.setState({
				full_name: u.user_given_name + " " + u.user_family_name,
				email: u.user_email,
				editEmail: u.user_email,
				phone_number: u.hasOwnProperty("user_phone_number")
					? u.user_phone_number
					: "",
				editPhone: u.hasOwnProperty("user_phone_number")
					? u.user_phone_number
					: "",
				editFirstName: u.user_given_name,
				editLastName: u.user_family_name,
				join_date: API.formatDate(u.created_at),
				photo
			});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	editProfile(e) {
		if (this._isMounted)
			this.setState({
				showEditForm: true
			});
	}

	editEmail(e) {
		if (this._isMounted)
			this.setState({
				editEmail: e.currentTarget.value
			});
	}

	editPhone(e) {
		if (this._isMounted)
			this.setState({
				editPhone: e.target.value
			});
	}

	editFirstName(e) {
		if (this._isMounted)
			this.setState({
				editFirstName: e.currentTarget.value
			});
	}

	editLastName(e) {
		if (this._isMounted)
			this.setState({
				editLastName: e.currentTarget.value
			});
	}

	saveProfile(e) {
		let data = {
			given_name: this.state.editFirstName,
			family_name: this.state.editLastName,
			phone_number: this.state.editPhone
		};

		if (this._isMounted)
			this.setState({
				isSaving: true
			});

		USER.saveProfileDetails(data, json => {
			if (json.success) {
				let u = AUTH.getUserData();
				u.user_family_name = this.state.editLastName;
				u.user_given_name = this.state.editFirstName;
				u.user_phone_number = this.state.editPhone;

				AUTH.updateUserData({ user: u });

				if (this._isMounted)
					this.setState({
						full_name: u.user_given_name + " " + u.user_family_name,
						phone_number: u.user_phone_number,
						showEditForm: false,
						isSaving: false
					});
			}
		});
	}

	changePhoto(e) {
		let file = e.currentTarget.files[0];
		let readerBinary = new FileReader();
		let readerBase64 = new FileReader();

		readerBinary.onload = e => {
			var exif = EXIF.readFromBinaryFile(e.currentTarget.result);

			readerBase64.onload = e => {
				let base64Img = e.currentTarget.result;
				// create an off-screen canvas
				var offScreenCanvas = document.createElement("canvas"),
					ctx = offScreenCanvas.getContext("2d");

				// cteate Image
				var img = new Image();
				img.onload = e => {
					// set its dimension to rotated size

					switch (exif.Orientation) {
						case 8:
							offScreenCanvas.height = img.width;
							offScreenCanvas.width = img.height;
							ctx.rotate((90 * Math.PI) / 180);
							ctx.translate(0, -offScreenCanvas.width);
							ctx.rotate((90 * Math.PI) / 180);
							ctx.translate(0, -offScreenCanvas.height);
							ctx.rotate((90 * Math.PI) / 180);
							ctx.translate(0, -offScreenCanvas.width);
							break;
						case 3:
							offScreenCanvas.height = img.height;
							offScreenCanvas.width = img.width;
							ctx.rotate((90 * Math.PI) / 180);
							ctx.translate(0, -offScreenCanvas.width);
							ctx.rotate((90 * Math.PI) / 180);
							ctx.translate(0, -offScreenCanvas.height);
							break;
						case 6:
							offScreenCanvas.height = img.width;
							offScreenCanvas.width = img.height;
							ctx.rotate((90 * Math.PI) / 180);
							ctx.translate(0, -offScreenCanvas.width);
							break;
						default:
							break;
					}

					ctx.drawImage(img, 0, 0);

					if (this._isMounted)
						this.setState({
							file: file,
							photo: offScreenCanvas.toDataURL("image/jpeg", 80),
							changedPhoto: true
						});
				};
				img.src = base64Img;
			};

			readerBase64.readAsDataURL(file);
		};

		readerBinary.readAsArrayBuffer(file);
	}

	uploadPhoto(e) {
		if (this._isMounted)
			this.setState({
				isUploading: true
			});

		USER.uploadProfilePhoto(
			{ img_data: this.state.photo },
			json => {
				if (json.success) {
					let u = AUTH.getUserData();
					u.user_photo_path = json.path;

					AUTH.updateUserData({ user: u });

					if (this._isMounted)
						this.setState({
							isUploading: false,
							changedPhoto: false
						});
				}
			},
			(e, percent) => {
				if (this._isMounted) {
					this.setState({
						uploadProgress: (e.loaded / e.total) * 100 + "%"
					});
				}
			}
		);
	}

	render() {
		let relative = this.props.location.pathname;
		let back = relative.substring(0, relative.lastIndexOf("/"));

		return (
			<div id="profile-settings-page" className="settings-page">
				<input
					type="file"
					accept="*/image"
					id="inputPhoto"
					onChange={this.changePhoto.bind(this)}
					style={{ width: 0, height: 0, opacity: 0 }}
				/>
				<SubPageHeader
					icon={["fal", "chevron-left"]}
					text="Profile"
					backLink={back}
					afterElements={
						this.state.showEditForm ? (
							this.state.isSaving ? (
								<button
									className="btn btn-link text-white"
									style={{ fontSize: 16, minWidth: 48 }}
								>
									<FontAwesomeIcon
										icon={["fas", "spinner-third"]}
										className="fa-spin"
									/>
								</button>
							) : (
								<button
									className="btn btn-link text-white"
									style={{ fontSize: 16, minWidth: 48 }}
									onClick={this.saveProfile.bind(this)}
								>
									<FontAwesomeIcon icon={["fas", "save"]} />
								</button>
							)
						) : (
							<button
								className="btn btn-link text-white mr-2"
								style={{ fontSize: 16, minWidth: 48 }}
								onClick={this.editProfile.bind(this)}
							>
								<FontAwesomeIcon icon={["fas", "pencil"]} />
							</button>
						)
					}
				/>
				<div id="settings-scroll">
					<div id="settings-header">
						<button
							className={
								"photo" +
								(this.state.photo !== null ? "" : " no-photo")
							}
							style={
								this.state.photo !== null
									? {
											backgroundImage:
												"url('" +
												this.state.photo +
												"')"
									  }
									: { backgroundImage: 'url("' + logo + '")' }
							}
							onClick={() => {
								$("#inputPhoto").click();
							}}
						>
							<FontAwesomeIcon icon={["fas", "camera-retro"]} />
						</button>
						<h1>{this.state.full_name}</h1>
						<p>{this.state.join_date}</p>
						{this.state.changedPhoto &&
							(this.state.isUploading ? (
								<Fragment>
									<div className="progress mb-2">
										<div
											className="progress-bar progress-bar-striped progress-bar-animated"
											style={{
												width: this.state.uploadProgress
											}}
										/>
									</div>
									<button className="btn btn-sm btn-me">
										<FontAwesomeIcon
											icon={["fas", "spinner-third"]}
											className="fa-spin mr-2"
										/>{" "}
										Uploading...
									</button>
								</Fragment>
							) : (
								<button
									className="btn btn-sm btn-me"
									onClick={this.uploadPhoto.bind(this)}
								>
									Upload Photo
								</button>
							))}
					</div>
					<ul id="menu-items">
						{this.state.showEditForm && (
							<Fragment>
								<canvas id="c" />
								<li className="px-0">
									<input
										type="text"
										placeholder="First Name"
										className="form-control border-0 h-100 border-radius-0"
										onChange={this.editFirstName.bind(this)}
										value={this.state.editFirstName}
									/>
								</li>
								<li className="px-0">
									<input
										type="text"
										placeholder="Last Name"
										className="form-control border-0 h-100 border-radius-0"
										onChange={this.editLastName.bind(this)}
										value={this.state.editLastName}
									/>
								</li>
							</Fragment>
						)}
						{this.state.showEditForm ? (
							""
						) : (
							<li
								className={
									this.state.showEditForm ? "px-0" : ""
								}
							>
								{this.state.full_name !== null ? (
									<span>
										<FontAwesomeIcon
											icon={["fas", "user"]}
											className="mr-3"
										/>
										{this.state.full_name}
									</span>
								) : (
									<span>
										<FontAwesomeIcon
											icon={["fas", "spinner-third"]}
											className="fa-spin mr-3"
										/>
										Loading...
									</span>
								)}
							</li>
						)}
						<li className={this.state.showEditForm ? "px-0" : ""}>
							{this.state.showEditForm ? (
								<NumberFormat
									format="+1 (###) ###-####"
									mask="_"
									className="border-0 form-control h-100 border-radius-0"
									placeholder="Your Mobile Number"
									onChange={this.editPhone.bind(this)}
									value={this.state.editPhone}
								/>
							) : this.state.phone_number !== null ? (
								<span>
									<FontAwesomeIcon
										icon={["fas", "phone"]}
										className="mr-3"
									/>
									{this.state.phone_number}
								</span>
							) : (
								<span>
									<FontAwesomeIcon
										icon={["fas", "spinner-third"]}
										className="fa-spin mr-3"
									/>
									Loading...
								</span>
							)}
						</li>
						<li className="disabled">
							{this.state.email !== null ? (
								<span>
									<FontAwesomeIcon
										icon={["fas", "envelope"]}
										className="mr-3"
									/>
									{this.state.email}
								</span>
							) : (
								<span>
									<FontAwesomeIcon
										icon={["fas", "spinner-third"]}
										className="fa-spin mr-3"
									/>
									Loading...
								</span>
							)}
						</li>
					</ul>
				</div>
			</div>
		);
	}
}

export default ProfileSettingsPage;
