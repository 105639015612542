/*
 * @Author: Matthew Auld
 * @Date: 2019-02-09 14:01:07
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-02-17 22:18:39
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import Geocode from "react-geocode";
import $ from "jquery";
import "./make-page.scss";
import { Redirect } from "react-router-dom";
import PageHeader from "../../components/SubPageHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MEMORIES } from "../../API";
import Swal from "sweetalert2";

Geocode.setApiKey("AIzaSyBXRkHWqrOxwk164r1BddYdHElI1vhd_yQ");

class MakePage extends Component {
	_isMounted = false;
	_uploadedPhotos = [];
	state = {
		feelings: [],
		keywords: [],
		images: [],
		isSaving: false,
		geo: {
			lat: 0,
			lng: 0
		},
		country: null,
		redirectTo: null
	};

	componentDidMount() {
		this._isMounted = true;

		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				this.getLocation.bind(this),
				() => {
					$("#txtLocation").attr(
						"placeholder",
						"Unable to get the closest address."
					);
				},
				{ enableHighAccuracy: true }
			);
		}
	}

	getLocation(position) {
		this.setState({
			geo: {
				lat: position.coords.latitude,
				lng: position.coords.longitude
			}
		});
		Geocode.fromLatLng(
			position.coords.latitude,
			position.coords.longitude
		).then(
			response => {
				const address = response.results[0].formatted_address;
				let country_code = null;
				response.results[0].address_components.forEach(c => {
					if (c.types.indexOf("country") >= 0) {
						country_code = c.short_name;
					}
				});
				if (this._isMounted)
					this.setState({
						country: country_code
					});
				$("#txtLocation").val(address);
			},
			error => {
				$("#txtLocation")
					.attr(
						"placeholder",
						"Unable to get an address for your locaton."
					)
					.val("");
			}
		);
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	showAttachments() {
		$(".attachments").toggleClass("open");
	}

	saveMemory() {
		const post = $("#txtPost").val();
		const title = $("#txtTitle").val();
		const locText = $("#txtLocation").val();
		const keywords = this.state.keywords.join("`");
		const { geo } = this.state;
		const { country } = this.state;
		const feelings = this.state.feelings.join("`");

		let data = {
			title,
			post,
			keywords,
			feelings,
			latitude: geo.lat,
			longitude: geo.lng,
			country,
			location_title: locText
		};

		if (this._isMounted)
			this.setState({
				isSaving: true
			});

		$("input,textarea,button").attr("disabled", "disabled");

		$("#saved-keywords,.feelingSection,#image-wrapper").addClass(
			"disabled"
		);

		MEMORIES.createMemory(data, json => {
			if (json.success) {
				if (this.state.images.length > 0) {
					this.state.images.forEach(img => {
						MEMORIES.attachPhoto(
							json.memory_id,
							img.file,
							(e, p) => {
								let pct = p.loaded / p.total;
								$("#photo-" + img.time).css(
									"width",
									pct * 100 + "%"
								);
							},
							data => {
								if (data.success) {
									this._uploadedPhotos.push(data);
									if (
										this._uploadedPhotos.length ===
										this.state.images.length
									) {
										Swal.fire({
											title:
												"ME.mory successfully saved!",
											text:
												"Your ME.mory and photos have been saved.",
											type: "success"
										}).then(() => {
											if (this._isMounted) {
												this.setState({
													redirectTo: "/"
												});
											}
										});
									}
								}
							}
						);
					});
				} else {
					Swal.fire({
						title: "ME.mory successfully saved!",
						text: "Your ME.mory has been saved.",
						type: "success"
					}).then(() => {
						if (this._isMounted) {
							this.setState({
								redirectTo: "/"
							});
						}
					});
				}
			}
		});
	}

	handleKeywords(e) {
		if (e.keyCode === 13) {
			let keywords = this.state.keywords;
			keywords.push(e.currentTarget.value);
			e.currentTarget.value = "";
			if (this._isMounted)
				this.setState({
					keywords
				});
		}
	}

	attachImage(e) {
		let files = e.currentTarget.files;
		let images = this.state.images;
		let reader = new FileReader();
		reader.onload = r => {
			let img = {
				preview: r.target.result,
				file: files[0],
				time: new Date().getTime()
			};
			images.push(img);
			this.setState({
				images
			});
		};
		reader.readAsDataURL(files[0]);
	}

	handlePhotos(e, evt) {
		let images = this.state.images;
		images.splice(images.indexOf(e) - 1, 1);

		if (this._isMounted)
			this.setState({
				images
			});
	}

	removeKeyword(e, evt) {
		let keywords = this.state.keywords;
		keywords.splice(keywords.indexOf(e) - 1, 1);

		if (this._isMounted)
			this.setState({
				keywords
			});
	}

	render() {
		if (this.state.redirectTo !== null)
			return <Redirect to={this.state.redirectTo} />;

		return (
			<div id="make-page">
				<div className="filler">
					<PageHeader
						text="New ME.mory"
						icon={["fas", "brain"]}
						afterElements={
							<button
								className="btnSave"
								onClick={this.saveMemory.bind(this)}
							>
								<FontAwesomeIcon
									icon={["fas", "paper-plane"]}
								/>
							</button>
						}
					/>
					<div className="page-rest">
						<div className="row">
							<div className="col-12">
								<input
									id="txtTitle"
									type="text"
									className="form-control"
									placeholder="ME.mory Title"
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-12 feelingSection">
								<p>How are you feeling today?</p>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<input
									type="text"
									id="txtLocation"
									className="form-control"
									placeholder="Loading approximate address..."
								/>
							</div>
						</div>
						<div className="row" style={{ flex: "1 0 auto" }}>
							<div className="col-12">
								<div id="saved-keywords">
									{this.state.keywords.map((k, key) => (
										<div
											key={key}
											onClick={
												!this.state.isSaving
													? this.removeKeyword.bind(
															this,
															key
													  )
													: () => {}
											}
											className="keyword-blob"
										>
											{k}
										</div>
									))}
								</div>
								<input
									type="text"
									className="form-control"
									placeholder="Keywords"
									onKeyUp={this.handleKeywords.bind(this)}
								/>
							</div>
						</div>
						<div className="row filler">
							<div className="col-12">
								<textarea
									id="txtPost"
									placeholder="Write a memory here..."
									className="form-control"
								/>
							</div>
						</div>
						{this.state.images.length > 0 && (
							<div
								id="image-wrapper"
								className="row mx-0 border-top pb-3"
								style={{ flex: "1 0 auto" }}
							>
								<div className="col-12 px-2">
									<h1
										style={{
											fontSize: "1rem",
											fontWeight: "normal"
										}}
									>
										Images
									</h1>
									<div id="images">
										{this.state.images.map((i, key) => (
											<div
												onClick={
													!this.state.isSaving
														? this.handlePhotos.bind(
																this,
																key
														  )
														: () => {}
												}
												key={key}
												id={"photo-" + i.time}
												className="attached-photo"
												style={{
													backgroundImage:
														'url("' +
														i.preview +
														'")'
												}}
											>
												<div className="progress">
													<div
														className="progress-bar progress-bar-striped progress-bar-animated"
														style={{ width: "0%" }}
													/>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						)}
					</div>
					<input
						id="file-photos"
						type="file"
						accept="*/images"
						onChange={this.attachImage.bind(this)}
						style={{
							visibility: "hidden",
							width: 1,
							height: 1,
							display: "block",
							position: "absolute",
							left: -10
						}}
					/>
					<div className="page-bottom">
						<button
							className="btn btn-block btn-secondary rounded-0"
							onClick={this.showAttachments.bind(this)}
						>
							Attachment(s)
						</button>
						<div className="attachments">
							<button
								onClick={() => {
									$("#file-photos").click();
								}}
								className="btn btn-block btn-me rounded-0"
							>
								<FontAwesomeIcon
									icon={["fas", "camera-retro"]}
									className="mr-3"
								/>
								Add a Photo
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default MakePage;
