/*
 * @Author: Matthew Auld
 * @Date: 2019-02-09 15:46:43
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-02-09 15:52:15
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { AUTH } from "../API";

class LogoutPage extends Component {
	state = { redirect: false };

	componentDidMount() {
		if (this.props.real) {
			AUTH.clearAccessToken();

			this.setState({
				redirect: true
			});
			this.props.onLogin(false);
		} else {
			this.setState({
				redirect: true
			});
		}
	}

	render() {
		if (this.state.redirect) return <Redirect to="/" />;
		return <div />;
	}
}

export default LogoutPage;
