/*
 * @Author: Matthew Auld
 * @Date: 2019-02-21 04:52:47
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-02-21 05:23:29
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import SubPageHeader from "../../../components/SubPageHeader";
import "../settings-page.scss";

class CameraGeneralSettingsPage extends Component {
	state = {};
	render() {
		let relative = this.props.location.pathname;
		let back = relative.substring(0, relative.lastIndexOf("/"));

		return (
			<div id="camera-settings" className="settings-page">
				<SubPageHeader
					icon={["fal", "chevron-left"]}
					text="Camera"
					backLink={back}
					afterElements={
						<div style={{ width: 32, marginRight: ".5rem" }} />
					}
				/>
				<ul id="menu-items">
					<li className="disabled border-0" />
					<li className="disabled">
						Save Pictures
						<label className="switch">
							<input type="checkbox" disabled="disabled" />
							<span className="slider round" />
						</label>
					</li>
					<li className="disabled">
						<p className="description">
							A copy of the pictures you take inside the ME.mory
							app will be saved onto your camera roll as well.
						</p>
					</li>
					<li className="disabled">
						<button>
							Image Size<span>Large</span>
						</button>
					</li>
					<li className="disabled">
						<button>
							Image Quality<span>High</span>
						</button>
					</li>
				</ul>
			</div>
		);
	}
}

export default CameraGeneralSettingsPage;
