/*
 * @Author: Matthew Auld
 * @Date: 2019-02-10 06:53:07
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-02-10 08:18:44
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import "./PhotoPreview.scss";

class PhotoPreview extends Component {
	_isMounted = false;
	state = { image: null, showModal: false };
	componentDidMount() {
		this._isMounted = true;

		this.setState({
			image: this.props.image
		});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	render() {
		return (
			<div
				className="col-3 photo-preview px-1"
				onClick={() => {
					this.props.onSelectPhoto(
						this.state.image !== null
							? this.state.image.file_path
							: ""
					);
				}}
			>
				{this.state.image !== null && (
					<div
						className="img"
						style={{
							backgroundImage:
								'url("https://www.jumpbuttonstudio.com/me.mory/' +
								this.state.image.file_path +
								'")'
						}}
					/>
				)}
			</div>
		);
	}
}

export default PhotoPreview;
