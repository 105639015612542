/*
 * @Author: Matthew Auld
 * @Date: 2019-02-09 05:45:58
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-02-09 06:55:28
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import "./LandingHeader.scss";
import Logo from "../logo.svg";

class LandingHeader extends Component {
	_isMounted = false;
	state = { animated: false };

	componentDidMount() {
		this._isMounted = true;

		if (this._isMounted) {
			this.setState({
				animated:
					this.props.hasOwnProperty("animated") && this.props.animated
			});
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	render() {
		return (
			<div className="landing-header">
				<h1 className="text-center">
					<img
						src={Logo}
						alt="ME.mory Brain Logo"
						className={this.state.animated ? "animated pulse" : ""}
					/>
					<span
						className={this.state.animated ? "animated tada" : ""}
					>
						ME
					</span>
					<span
						className={this.state.animated ? "animated tada" : ""}
					>
						.mory
					</span>
				</h1>
				<p
					className={
						"lead text-center" +
						(this.state.animated ? "  animated jackInTheBox" : "")
					}
				>
					Sign up to experience a revolutionary new way of
					remembering.
				</p>
			</div>
		);
	}
}

export default LandingHeader;
