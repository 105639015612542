/*
 * @Author: Matthew Auld
 * @Date: 2019-02-10 01:20:15
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-02-21 11:57:15
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import PageHeader from "../../components/SubPageHeader";
import { MEMORIES, API } from "../../API";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import "./view-page.scss";
import PhotoPreview from "../../components/PhotoPreview";
import FullPhotoView from "../../components/FullPhotoView";
import Swal from "sweetalert2";

class ViewPage extends Component {
	_isMounted = false;
	state = {
		isLoading: true,
		memory: null,
		showFullPhoto: false,
		searchTerm: null,
		highlight: false,
		showOptions: false
	};

	componentDidMount() {
		this._isMounted = true;

		console.log(this.props);

		if (window.viewObj === undefined) {
			let v = parseInt(this.props.match.params.memory_id);
			MEMORIES.loadDetails(v, json => {
				if (this._isMounted)
					this.setState({
						memory: json.memory,
						isLoading: false
					});
			});
		} else {
			if (this._isMounted)
				this.setState({
					memory: window.viewObj,
					isLoading: false
				});
		}

		const { location } = this.props;

		let search = location.search;
		if (search.startsWith("?")) {
			let parts = search.substr(1).split("&");
			let query = {};
			parts.forEach(p => {
				let t = p.split("=");
				query[t[0]] = t[1];
			});

			if (this._isMounted)
				this.setState({
					highlight: query.highlight === "true",
					searchTerm: query.term
				});
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	toggleTitle(e) {
		$(e.currentTarget).toggleClass("show");
	}

	showFullPhoto(e) {
		if (this.state.fullPhoto !== e || !this.state.showFullPhoto) {
			this.setState({
				showFullPhoto: true,
				fullPhoto: e
			});
		}
	}

	hideFullPhoto() {
		this.setState({
			showFullPhoto: false
		});
	}

	deleteMemory() {
		Swal.fire({
			title: "Delete that memory?",
			html:
				"Are you sure you want to delete this memory?<br /><br />This <b>CANNOT</b> be undone!",
			type: "question",
			cancelButtonText: "No",
			confirmButtonText: "Yes",
			confirmButtonColor: "#EF5669",
			showCancelButton: true
		}).then(v => {
			if (v.hasOwnProperty("value") && v.value) {
				MEMORIES.deleteMemory(this.state.memory.memory_id, json => {
					if (json.success) {
						Swal.fire({
							title: "Delete Successful",
							text: "That ME.mory was deleted successfully!",
							type: "success"
						}).then(() => {
							if (this._isMounted) {
								localStorage.removeItem("home_memories");
								this.setState({
									redirectTo: true
								});
							}
						});
					}
				});
			}
		});
	}

	render() {
		let relative = this.props.location.pathname;
		console.log(relative);

		if (this.state.redirectTo) {
			return <Redirect to="/" />;
		}

		let re = new RegExp("\\b(" + this.state.searchTerm + ")\\b", "ig");

		return (
			<div
				id="view-page"
				className={this.state.showFullPhoto ? "blurred" : ""}
			>
				<PageHeader
					text="View ME.mory"
					icon={["fas", "chevron-left"]}
					backLink={
						window.hasOwnProperty("previousLocation")
							? window.previousLocation +
							  this.props.location.search
							: "/"
					}
					afterElements={
						<div style={{ width: 32, marginRight: ".5rem" }} />
					}
				/>
				<div
					className={
						"page-rest" + (this.state.isLoading ? " loading" : "")
					}
				>
					{!this.state.isLoading && (
						<div className="container d-flex flex-column h-100">
							{this.state.memory.memory_title !== "" &&
								this.state.memory.memory_title !== null && (
									<div className="row">
										<div className="col-12 text-center">
											<h1
												onClick={this.toggleTitle.bind(
													this
												)}
												dangerouslySetInnerHTML={
													this.state.highlight &&
													this.state.searchTerm !==
														null
														? {
																__html: this.state.memory.memory_title.replace(
																	re,
																	function(
																		e
																	) {
																		return `<span class="highlight">${e.trim()}</span>`;
																	}
																)
														  }
														: {
																__html: this
																	.state
																	.memory
																	.memory_title
														  }
												}
											/>
										</div>
									</div>
								)}
							<div className="row">
								<div className="col-12">
									<span className="date">
										{API.formatDate(
											this.state.memory.created_at
										)}
									</span>
									<br />
									<span className="location">
										{this.state.memory.memory_location_text}
									</span>
								</div>
							</div>
							{this.state.memory.memory_keywords !== null &&
								this.state.memory.memory_keywords.length >
									0 && (
									<div className="row">
										<div className="col-12 keywords">
											{this.state.memory.memory_keywords.map(
												(keyword, key) => (
													<div
														key={key}
														className="keyword-blob"
													>
														{keyword}
													</div>
												)
											)}
										</div>
									</div>
								)}
							<div className="row memory-post">
								<div className="col-12">
									<div
										className="memory-text"
										dangerouslySetInnerHTML={
											this.state.highlight &&
											this.state.searchTerm !== null
												? {
														__html: this.state.memory.memory_post.replace(
															re,
															function(e) {
																return `<span class="highlight">${e.trim()}</span>`;
															}
														)
												  }
												: {
														__html: this.state
															.memory.memory_post
												  }
										}
									/>
								</div>
							</div>
							{this.state.memory.hasOwnProperty("images") &&
								this.state.memory.images.length > 0 && (
									<Fragment>
										<div className="row">
											<div className="col-12">Images</div>
										</div>
										<div className="row py-3 d-flex flex-direction flex-nowrap overflow-auto">
											{this.state.memory.images.map(
												(i, key) => (
													<PhotoPreview
														image={i}
														key={key}
														onSelectPhoto={this.showFullPhoto.bind(
															this
														)}
													/>
												)
											)}
										</div>
									</Fragment>
								)}
							<div
								id="options"
								className={this.state.showOptions ? "show" : ""}
							>
								<button
									onClick={() => {
										if (this._isMounted)
											this.setState({
												showOptions: !this.state
													.showOptions
											});
									}}
									className="btn btn-block btn-me text-white rounded-0"
								>
									...
								</button>
								<ul className="m-0 p-0 w-100">
									<li>
										<button
											onClick={this.deleteMemory.bind(
												this
											)}
											className="btn btn-block btn-danger rounded-0"
										>
											Delete ME.mory
										</button>
									</li>
								</ul>
							</div>
						</div>
					)}
					{this.state.isLoading && (
						<div>
							<FontAwesomeIcon
								icon={["fas", "spinner-third"]}
								className="fa-spin mr-2"
							/>{" "}
							Loading Me.mory...
						</div>
					)}
				</div>
				{this.state.showFullPhoto && (
					<FullPhotoView
						onClose={this.hideFullPhoto.bind(this)}
						photo={this.state.fullPhoto}
					/>
				)}
			</div>
		);
	}
}

export default ViewPage;
