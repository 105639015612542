/*
 * @Author: Matthew Auld
 * @Date: 2019-02-09 05:38:09
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-02-09 06:45:00
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./landing-page.scss";
import LandingHeader from "../components/LandingHeader";

class LandingPage extends Component {
	state = {};
	render() {
		return (
			<div id="landing-page" className="pt-5 landing-page">
				<LandingHeader animated={true} />
				<div id="landing-selection">
					<div className="row">
						<div className="col-12">
							<Link
								to="/register"
								className="btn btn-me btn-block"
							>
								Sign Up
							</Link>
						</div>
					</div>
					<div className="row">
						<div className="col-12 text-center text-white py-3">
							OR
						</div>
					</div>
					<div className="row">
						<Link
							to="/login"
							className="btn btn-link btn-block text-white"
						>
							Already have an account? Sign In
						</Link>
					</div>
				</div>
			</div>
		);
	}
}

export default LandingPage;
