import React, { Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
/* Pages */
import LandingPage from "./pages/landing-page.jsx";
import LoginPage from "./pages/login-page.jsx";
import RegisterPage from "./pages/register-page.jsx";

import NavPage from "./pages/nav-page.jsx";
import MakePage from "./pages/sub-pages/make-page.jsx";
import HomePage from "./pages/sub-pages/home-page.jsx";

import "./App.scss";
import { AUTH } from "./API.js";
import LogoutPage from "./pages/logout.jsx";
import ViewPage from "./pages/sub-pages/view-page.jsx";
import SearchPage from "./pages/sub-pages/search-page.jsx";
import SettingsPage from "./pages/sub-pages/settings-page.jsx";
import GeneralSettings from "./pages/sub-pages/settings/settings-general.jsx";
import AboutGeneralSettingsPage from "./pages/sub-pages/settings/settings-general-about.jsx";
import CameraGeneralSettingsPage from "./pages/sub-pages/settings/settings-general-camera.jsx";
import DateTimeGeneralSettingsPage from "./pages/sub-pages/settings/settings-general-data-time.jsx";
import PlanInformationSettingsPage from "./pages/sub-pages/settings/settings-plan-information.jsx";
import ProfileSettingsPage from "./pages/sub-pages/settings/settings-profile.jsx";
import MemoriesGeneralSettingsPage from "./pages/sub-pages/settings/settings-general-memories.jsx";

class App extends Component {
	_previousLocation = null;
	_isMounted = false;

	state = { isLoggedIn: false };

	componentDidMount() {
		this._isMounted = true;
		if (AUTH.hasAccessToken()) {
			this.setState({
				isLoggedIn: true
			});
			AUTH.isAccessTokenValid(json => {
				if (!json.success) {
					AUTH.clearAccessToken();
					this.setState({
						isLoggedIn: false
					});
				}
			});
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	setLoginState(isLoggedIn) {
		this.setState({
			isLoggedIn: isLoggedIn
		});
	}

	componentWillReceiveProps() {
		window.previousLocation = this.props.location.pathname;
	}

	render() {
		return (
			<div id="app">
				{!this.state.isLoggedIn && (
					<Switch>
						<Route exact path="/" component={LandingPage} />
						<Route
							exact
							path="/login"
							component={props => (
								<LoginPage
									onLogin={this.setLoginState.bind(this)}
									{...props}
								/>
							)}
						/>
						<Route
							exact
							path="/register"
							component={RegisterPage}
						/>
						<Route
							exact
							path="/logout"
							component={props => (
								<LogoutPage
									real={false}
									onLogin={this.setLoginState.bind(this)}
									{...props}
								/>
							)}
						/>
						<Route
							component={() => {
								return <Redirect to="/" />;
							}}
						/>
					</Switch>
				)}
				{this.state.isLoggedIn && (
					<Switch>
						<Route exact path="/" component={NavPage} />
						<Route exact path="/make" component={MakePage} />
						<Route
							exact
							path="/settings"
							component={SettingsPage}
						/>

						<Route
							exact
							path="/settings/plan-information"
							component={PlanInformationSettingsPage}
						/>
						<Route
							exact
							path="/settings/general"
							component={GeneralSettings}
						/>
						<Route
							exact
							path="/settings/general/about"
							component={AboutGeneralSettingsPage}
						/>
						<Route
							exact
							path="/settings/general/camera"
							component={CameraGeneralSettingsPage}
						/>
						<Route
							exact
							path="/settings/general/date-and-time"
							component={DateTimeGeneralSettingsPage}
						/>
						<Route
							exact
							path="/settings/general/memories"
							component={MemoriesGeneralSettingsPage}
						/>
						<Route
							exact
							path="/settings/profile"
							component={ProfileSettingsPage}
						/>
						<Route
							exact
							path="/home"
							component={props => <HomePage {...props} />}
						/>
						<Route
							exact
							path="/logout"
							component={props => (
								<LogoutPage
									real={true}
									onLogin={this.setLoginState.bind(this)}
									{...props}
								/>
							)}
						/>
						<Route
							exact
							path="/view/:memory_id"
							component={ViewPage}
						/>
						<Route exact path="/search" component={SearchPage} />
						<Route
							component={() => {
								return <Redirect to="/" />;
							}}
						/>
					</Switch>
				)}
			</div>
		);
	}
}

export default withRouter(App);
