/*
 * @Author: Matthew Auld
 * @Date: 2019-02-10 08:10:22
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-02-10 08:29:51
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import "./FullPhotoView.scss";

class FullPhotoView extends Component {
	_isMounted = false;
	state = { photo: null };

	componentDidMount() {
		this._isMounted = true;

		if (this._isMounted)
			this.setState({
				photo: this.props.photo
			});
	}

	componentWillReceiveProps(nextProps) {
		if (this.state.photo !== nextProps.photo)
			this.setState({
				photo: nextProps.photo
			});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	render() {
		return (
			<div
				id="photo-view"
				onClick={() => {
					if (this.props.hasOwnProperty("onClose")) {
						this.props.onClose();
					}
				}}
			>
				<img
					src={
						"https://www.jumpbuttonstudio.com/me.mory/" +
						this.state.photo
					}
					alt=""
				/>
			</div>
		);
	}
}

export default FullPhotoView;
