/*
 * @Author: Matthew Auld
 * @Date: 2019-02-09 16:42:24
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-02-21 11:36:55
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import "./MemoryPreview.scss";
import { API } from "../API";

class MemoryPreview extends Component {
	_isMounted = false;
	state = {
		memory: null,
		searchTerm: null,
		redirect: false,
		highlight: false
	};

	componentDidMount() {
		this._isMounted = true;
		let searchTerm = null;

		if (
			this.props.searchTerm !== null &&
			this.props.searchTerm !== undefined
		) {
			searchTerm = this.props.searchTerm;
		}

		let highlight = this.props.hasOwnProperty("highlight")
			? this.props.highlight
			: false;

		this.setState({
			memory: this.props.memory,
			searchTerm,
			highlight
		});
	}

	componentWillReceiveProps(nextProps) {
		let searchTerm = null;
		if (
			nextProps.searchTerm !== null &&
			nextProps.searchTerm !== undefined
		) {
			searchTerm = nextProps.searchTerm;
		}

		let highlight = this.props.hasOwnProperty("highlight")
			? this.props.highlight
			: false;

		this.setState({
			searchTerm,
			highlight
		});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	gotoMemory() {
		window.viewObj = this.state.memory;
		this.setState({
			redirect: true
		});
	}

	render() {
		let params = {};
		if (this.state.highlight !== false) {
			params.highlight = this.state.highlight;
		}

		if (this.state.searchTerm !== null) {
			params.term = this.state.searchTerm;
		}

		if (this.state.redirect)
			return (
				<Redirect
					to={
						"/view/" +
						this.state.memory.memory_id +
						API.formatParams(params)
					}
				/>
			);

		const re = new RegExp(
			"\\b(" +
				(this.state.searchTerm !== null
					? this.state.searchTerm.toLowerCase()
					: "") +
				")\\b",
			"ig"
		);

		return (
			<div
				className={
					"memory-preview animated bounceInLeft " +
					(this.props.hasOwnProperty("delay")
						? "timeOffset-" + this.props.delay
						: "")
				}
				onClick={this.gotoMemory.bind(this)}
			>
				{this.state.memory !== null && (
					<div className="row mx-0">
						<div className="col-3 px-0">
							<div
								className={
									"memory-thumb " +
									(this.state.memory !== null &&
									this.state.memory.hasOwnProperty(
										"images"
									) &&
									this.state.memory.images.length >= 1
										? ""
										: "default")
								}
								style={
									this.state.memory !== null &&
									this.state.memory.hasOwnProperty(
										"images"
									) &&
									this.state.memory.images.length >= 1
										? {
												backgroundImage:
													"url('https://www.jumpbuttonstudio.com/me.mory/" +
													this.state.memory.images[0]
														.file_path +
													"')"
										  }
										: {}
								}
							/>
						</div>
						<div className="col-9">
							<div className="row">
								<div className="col-12">
									<h1
										dangerouslySetInnerHTML={
											this.state.searchTerm !== null &&
											this.state.highlight
												? {
														__html: this.state.memory.memory_title.replace(
															re,
															function(e) {
																return `<span class="highlight">${e.trim()}</span>`;
															}
														)
												  }
												: {
														__html: this.state
															.memory.memory_title
												  }
										}
									/>
								</div>
								<div className="col-12">
									<span className="date">
										{this.state.hasOwnProperty("epoch")
											? API.formatDate(
													new Date(
														this.state.epoch
													).toISOString()
											  )
											: API.formatDate(
													this.state.memory.created_at
											  )}
									</span>
								</div>
								<div className="col-12">
									<div
										className="text"
										dangerouslySetInnerHTML={
											this.state.searchTerm !== null &&
											this.state.highlight
												? {
														__html: this.state.memory.memory_post.replace(
															re,
															function(e) {
																return `<span class="highlight">${e.trim()}</span>`;
															}
														)
												  }
												: {
														__html: this.state
															.memory.memory_post
												  }
										}
									/>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default MemoryPreview;
