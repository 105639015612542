/*
 * @Author: Matthew Auld
 * @Date: 2019-02-09 07:32:43
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-02-21 13:58:34
 * Copyright 2019 JumpButton North - All rights reserved.
 */

module.exports = {
	API_URL: "https://memory-api.jumpbuttonstudio.com/api/",
	app_version: "3.0.0",
	FILE_URL: "https://www.jumpbuttonstudio.com/me.mory/"
};
